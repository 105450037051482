import React from 'react'
import { Link } from 'gatsby'
import styles from './sponsors.css'

export default () => (
  <div class="sponsors">
    <h1>Sponsors</h1>
    <div class="wrapper">
      <img src="/sponsors/sponsors1.jpg" />
      <img src="/sponsors/sponsors2.jpg" />
      <img src="/sponsors/sponsors3.jpg" />
      <img src="/sponsors/sponsors4.jpg" />
      <img src="/sponsors/sponsors5.jpg" />
      <img src="/sponsors/sponsors6.png" />
      <img src="/sponsors/sponsors7.jpg" />
    </div>
  </div>
)
