import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'
import logo from './Logo/logo.png'

export default () => (
  <nav role="navigation" className={styles.nav}>
    <div className={styles.logo}>
      <Link to="/"> 
        <img src={logo} alt="Les Zelles de Jagat" />
      </Link>
    </div>
    <ul className={styles.navigation}>
      <li className={styles.navigationItem}>
        <Link to="/">Accueil</Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/dons/">Dons</Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/#contact">Contact</Link>
      </li>
      <li className={styles.navigationItem}>
        <a href="/boutique/">Boutique</a>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/adhesion/">Adhésion</Link>
      </li>
    </ul>
  </nav>
)
